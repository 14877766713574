<template>
  <v-chip v-bind="$attrs" :color="ticketTag.color" :density="density ?? 'comfortable'" prepend-icon="$assetTag">
    <span class="text-high-emphasis">{{ ticketTag.description }}</span>
  </v-chip>
</template>

<script setup lang="ts">
const props = defineProps<{
  ticketTag: Pick<TicketTag, "_id" | "color" | "description">
  density?: "comfortable" | "compact" | "default"
}>()
const { ticketTag, density } = toRefs(props)
</script>
